import {ArrowRight} from "../../assets/icons"

function ShopSection() {
    return <section className="shop">
       <div className="shop__wrapper">
        <h2>YOUR ONE STOP SHOP FOR BOXING ENTERTAINMENT</h2>
        <h3>WHAT WE ARE WORKING ON</h3>
        <ArrowRight />
       </div>
    </section>
}

export default ShopSection;
