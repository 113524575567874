import SlideImage1 from "./slide1.jpg"
import SlideImage2 from "./slide2.jpg"
import SlideImage3 from "./slide3.jpg"
import SlideImage4 from "./slide4.jpg"
import SlideImage5 from "./slide5.jpg"
import SlideImage6 from "./slide6.jpg"
import SlideImage7 from "./slide7.jpg"
import SlideImage8 from "./slide8.jpg"
import SlideImage9 from "./slide9.jpg"

const ImagesArray = [SlideImage1, SlideImage2, SlideImage3, SlideImage4, SlideImage5, SlideImage6, SlideImage7, SlideImage8, SlideImage9]

export default ImagesArray;
