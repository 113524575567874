import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import sliderImages from '../../assets/img/swiper';

function OurFightSection() {
    return <section className="ourfight">
        <div className='ourfight__slider'>
            <div className='ourfight__slider-tab'>
                FIGHTWEAR
            </div>
            <Swiper
                spaceBetween={0}
                slidesPerView={3}
                loop
                autoplay={{delay: 3000}}
                modules={[Autoplay ]}
            >
                {sliderImages.map((image) => <SwiperSlide key={image}><img src={image} alt="swiper slide"/></SwiperSlide> )}
            </Swiper>
        </div>
        <div className='ourfight__stay-tuned'>
            <h2>STAY TUNED!</h2>
            <p>we’re only getting started...</p>
        </div>
    </section>
}

export default OurFightSection;
