import { GeneralEnquiries, InstagramIcon, Press, YoutubeIcon } from "../assets/icons";
import openLink from "./openLink";

export const socials = [
    {name: "instagram", Icon: InstagramIcon, account: "@outbx_", link: "https://instagram.com/outbx_"},
    {name: "youtube", Icon: YoutubeIcon, account: "@outbxentertainment", link: "https://www.youtube.com/@outbxentertainment"},
    {name: "general enquiries", Icon: GeneralEnquiries, account: "info@outbx.com", link: "mailto:info@outbx.com"},
    {name: "press", Icon: Press, account: "press@outbx.com", link: "mailto:press@outbx.com"},
]

export const DocsArray = [
    {
        title: "OUTBX BUSINESS PLAN",
        text: "OUTBX. The New standard in boxing entertainment",
        onClick: () => openLink("https://outbx.com/Business-overview.pdf")
    },
    {
        title: "BOX-CON EXPO",
        text: "A groundbreaking, all-inclusive boxing expo!",
        onClick:() => openLink("https://outbx.com/box-con.pdf")
    },
    {
        title: "TITLE SHOT",
        text: "The worlds first hybrid television drama/professional boxing tournament",
        onClick:() => openLink("https://outbx.com/title-shot.pdf")
    },
    {
        title: "OUTBX Management",
        text: "A 360 management company bringing trust and transparency back to the sport",
        onClick: () => openLink("https://outbx.com/mgmt.pdf")
    },
    {
        title: "Road To The OBC",
        text: "This is an illustration of how Outbx will change the mechanics of boxing with its cutting edge AI powered ecosystem.",
        onClick: () => openLink("https://outbx.com/road-to-the-obc.pdf")
    },
]
