import HeaderLeftImage from "../../assets/img/card1Image.png"
import PodcastImage1 from "../../assets/img/podcastImages/podcast1.jpg"
import PodcastImage2 from "../../assets/img/podcastImages/podcast2.jpg"
import PodcastImage3 from "../../assets/img/podcastImages/podcast3.jpg"
import GlovesImage from "../../assets/img/podcastImages/gloves.png"
import { YoutubeIcon } from "../../assets/icons";
import { ArrowRight } from "../../assets/icons";
import openLink from "../../helpers/openLink"
import { socials } from "../../helpers/constants"

function CardsSection() {
    return <section className="podcast">
       <div className="podcast__header">
        <div className="podcast__header-left">
            <div className="podcast__header-left__textbox">
                <h2>BRINGING YOU THE POPULAR NEW PODCAST</h2>
                <h2>FIGHTING ON THE INSIDE</h2>
            </div>
            <img src={HeaderLeftImage} alt="fighting on inside podcast"/>
        </div>
        <div className="podcast__header-right">
            <div className="podcast__header-right__youtube">
                <YoutubeIcon />
                <p>@OUTBXENTERTAINMENT</p>
            </div>
            <div className="podcast__header-right__button" onClick={() => openLink(socials.find(({name}) => name === "youtube").link)}>
                <p>CLICK HERE TO VISIT THE PODCAST</p>
                <div className="podcast__header-right__button__arrow">
                    <ArrowRight />
                </div>
            </div>
        </div>
       </div>
       <div className="podcast__body">
        <div className="podcast__body__left">
            <img src={PodcastImage1} alt="podcast1" />
        </div>
        <div className="podcast__body__right">
            <div className="podcast__body__right-top">
                <img src={PodcastImage2} alt="podcast2" />
                <img src={PodcastImage3} alt="podcast3" />
            </div>
            <div className="podcast__body__right-bottom">
                <p>PROUD SUPPORTERS OF GLOVES UP KNIVES DOWN</p>
                <img src={GlovesImage} alt="gloves" />
            </div>
        </div>
       </div>
    </section>;
}

export default CardsSection;
