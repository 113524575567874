import { RouterProvider, createBrowserRouter } from "react-router-dom";
import CardsSection from "./components/CardsSection";
import Footer from "./components/Footer";
import Hero from "./components/Hero"
import Hero2 from "./components/Hero/Hero2.jsx"
import OurFightSection from "./components/OurFightSection";
import ProudSection from "./components/ProudSection";
import ShopSection from "./components/Shop";
import Socials from "./components/Socials";
import YoutubeSeries from "./components/YoutubeSeries";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <div className="App">
      <Hero />
      <CardsSection />
      <ShopSection />
      <YoutubeSeries />
      <ProudSection />
      <OurFightSection />
      <Socials />
      <Footer />
    </div>,
    },
    {
      path: "*",
      element: <div className="App">
      <Hero />
      <CardsSection />
      <ShopSection />
      <YoutubeSeries />
      <ProudSection />
      <OurFightSection />
      <Socials />
      <Footer />
    </div>,
    },
    {
      path: "/full-overview",
      element:  <Hero2 />
    },
  ]);


  return <RouterProvider router={router} />
}

export default App;
