import openLink from "../../../helpers/openLink";

function SocialCard({name, Icon, account, link}) {

    return <div className="social-card" onClick={() => openLink(link)}>
         <h3 className="social-card__name">{name}</h3>
         <Icon className="social-card__icon"/>
         <h3 className="social-card__account">{account}</h3>
    </div>;
}

export default SocialCard;
