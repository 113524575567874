import { InstagramIcon, YoutubeIcon } from "../../assets/icons";
import OutbxLogo from "../../assets/img/outbxLogo.png"
import { DocsArray, socials } from "../../helpers/constants";
import openLink from "../../helpers/openLink";
import DocBlock from "../common/DocBlock";

function Hero() {
    return <> <section className="hero2">
        <header className="hero2__header">
            <img src={OutbxLogo} alt="Outbx Logo" />
            <div className="hero2__socials">
                <InstagramIcon onClick={() => openLink(socials[0].link)} />
                <YoutubeIcon onClick={() => openLink(socials[1].link)} />
            </div>
        </header>
        <footer className="hero2__footer">
            <div className="hero2__footer-wrapper">
                <div className="hero2__footer-text">
                    <h1>WELCOME TO</h1>
                    <h1>OUTBX</h1>
                    <h2>SPORTS ENTERTAINMENT AT ITS FINEST</h2>
                <div className="hero2__footer-text-ambassador">
                    <h2>Ebanie Bridges</h2>
                    <h2>OUTBX AMBASSADOR</h2>
                </div>
                </div>
            </div>
            <div  className="hero2__footer-docboxes">
                {DocsArray.map((item) => <DocBlock {...item} key={item.title} />)}
            </div>
        </footer>
    </section>
            <div  className="hero2__footer-docboxes hero2__footer-docboxes-mobile">
                {DocsArray.map((item) => <DocBlock {...item} key={item.title} />)}
            </div>
    </>;
}

export default Hero;
