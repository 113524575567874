import { InstagramIcon, YoutubeIcon } from "../../assets/icons";
import OutbxLogo from "../../assets/img/outbxLogo.png"
import { socials } from "../../helpers/constants";
import openLink from "../../helpers/openLink";



function Hero() {
    return <> <section className="hero">
        <header className="hero__header">
            <img src={OutbxLogo} alt="Outbx Logo" />
            <div className="hero__socials">
                <InstagramIcon onClick={() => openLink(socials[0].link)} />
                <YoutubeIcon onClick={() => openLink(socials[1].link)} />
            </div>
        </header>
        <footer className="hero__footer">
            <div className="hero__footer-wrapper">
                <div className="hero__footer-text">
                    <h1>WELCOME TO</h1>
                    <h1>OUTBX</h1>
                    <h2>SPORTS ENTERTAINMENT AT ITS FINEST</h2>
                <div className="hero__footer-text-ambassador">
                    <h2>Ebanie Bridges</h2>
                    <h2>OUTBX AMBASSADOR</h2>
                </div>
                </div>
            </div>
            <div className="hero__yellow-box">
                FULL WEBSITE COMING SOON
            </div>
        </footer>
    </section>
    </>;
}

export default Hero;
