import { ArrowRight } from "../../assets/icons";
import proudCard from "../../assets/img/proudCard.png"
import residentImage from "../../assets/img/residentImage.png"

function ProudSection() {
    return <section className="proud">
       <div className="proud__top-block">
            <img className="proud__top-block_card"src={proudCard} alt="card"/>
            <h2>
                PROUD CREATORS OF <span>BOXING’S</span> VERY <span>FIRST</span> SPORTS COMEDY PANEL <span>SHOW!</span>
            </h2>
       </div>
       <div className="proud__bottom-block">
            <img className="proud__bottom-block_card"src={proudCard} alt="card"/>
            <div className="proud__bottom-block_textblock">
                <h2>WITH OUR RESIDENT <br/> COMIC FIN TAYLOR</h2>
                <h3>COMING SOON!</h3>
            </div>
            <img className="proud__bottom-block_resident-image" src={residentImage} alt="resident"/>
       </div>
      <div className="proud__merch">
      <div className="proud__merch-wrapper">
        <h2>OUTBX <br/> MERCHANDISE</h2>
        <h3>COMING SOON!</h3>
        <ArrowRight />
       </div>
      </div>
    </section>
}

export default ProudSection;
