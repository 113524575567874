import { ClipIcon } from "../../../assets/icons";

function DocBlock({title, text, onClick}) {
    return <div onClick={onClick} className="doc-box">
        <ClipIcon />
        <h3>{title}</h3>
        <p>{text}</p>
    </div>;
}

export default DocBlock;
