
import OutbxLogoBlack from "../../assets/img/outbxLogo_black.png"
import { socials } from "../../helpers/constants";
import SocialCard from "../common/SocialCard";

function Socials() {
    return (
        <section className="socials">
          <h2>WHERE TO FIND <span>OUTBX.</span></h2>
          <div className="socials__container">
            <div className="socials__container__first_card">
                    <img src={OutbxLogoBlack} alt="logo black" />
                </div>
                {socials.map(({name, Icon, account, link }) => (
                    <SocialCard name={name} Icon={Icon} account={account} key={account} link={link}/>
                ))}
          </div>
        </section>
    );
}

export default Socials;
